import React from 'react'
import get from 'lodash/get'
import PropTypes from 'prop-types';
import Paragraphs from '../helpers/paragraphs';

const HowToUse = (props) => {
    const entity = props.entity;
    if(props.mobile) {
        return (
            <div className="container-wide">
                <div className="body body--how-to-use row">
                    <h2 className="text-center">How to Use</h2>
                    {get(entity, 'image.file.url', false) &&
                        <div className="image">
                            <img className="img-fluid" alt={get(entity, 'image.title', 'Benefit Image')} src={get(entity, 'image.file.url', '')} />
                        </div>
                    }
                    <div className="text">
                        <h3>{get(entity, 'title', '')}</h3>
                        {get(entity, 'description', false) &&
                            <Paragraphs value={entity.description} />
                        }
                    </div>
                </div>
            </div>
        )
    } 
    return (
        <div className="container-wide">
            <div className="body body--how-to-use row">
                <div className="text w-45">
                    <h3>{get(entity, 'title', '')}</h3>
                    {get(entity, 'description', false) &&
                        <Paragraphs value={entity.description} />
                    }
                </div>
                <div className="image w-55">
                {get(entity, 'image.file.url', false) && <img className="img-fluid" alt={get(entity, 'image.title', 'How to Use Image')} src={get(entity,'image.file.url', '')} />}
                </div>
            </div>
        </div>
    )
}

HowToUse.propTypes = {
    entity: PropTypes.object,
    mobile: PropTypes.bool
}

export default HowToUse
