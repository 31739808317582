import React from 'react'
import { get, first } from 'lodash'
import PropTypes from 'prop-types'
import Layout from '../components/layout'
import { Carousel } from 'react-responsive-carousel'
import Benefits from '../components/product/benefits'
import HowToUse from '../components/product/howToUse'
import WhyItWorks from '../components/product/whyItWorks'
import DrugFactsIngredients from '../components/product/drugFactsIngredients'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import Paragraphs from '../components/helpers/paragraphs'
import { SitemapCrumbs } from 'gatsby-plugin-breadcrumb'
import RelatedProductsSlider from 'react-slick'
import { graphql, Link } from 'gatsby'
// import Select from 'react-select'
import "react-tabs/style/react-tabs.css"
import "react-responsive-carousel/lib/styles/carousel.min.css"
// import twitterIcon from '../assets/images/twitter-icon.svg'
// import fbIcon from '../assets/images/facebook-icon.svg'
import { getDataForLanguage, getComplexData } from '../utils/getDataForLanguage'

export const ProductPage = ({ data, location }) => {

    const pageData = get(data, "allContentfulProduct.edges", []);
    const crumbs = [
        {
            pathname: "/",
            crumbLabel: "Home"
        },
        {
            pathname: "/products",
            crumbLabel: "Products"
        },
        {
            pathname: location.pathname,
        }
    ];

    const customCrumbLabel = get(pageData, '[0].node.title', '');

    const settings = {
        accessibility: false,
        nav: true,
        dots: false,
        arrows: true,
        infinite: false,
        slidesToShow: 3,
        slidesToScroll: 2,
        speed: 500,
        centerMode: false,
        responsive: [
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            }
        ]
    };
    const transData = first(getDataForLanguage(pageData.map(item => item.node), process.env.COUNTRY));
    const product = transData;

    let benefitsData = first(getDataForLanguage(pageData.map(item => item.node.benefits), process.env.COUNTRY));
    console.log(benefitsData)
    let howToUseData = first(getDataForLanguage(pageData.map(item => item.node.howToUse), process.env.COUNTRY));
    let drugFactsIngredientsData = first(getDataForLanguage(pageData.map(item => item.node.drugFactsIngredients), process.env.COUNTRY));
    let whyItWorksData = first(getDataForLanguage(pageData.map(item => item.node.whyItWorks), process.env.COUNTRY));

    let relatedProductsData = getComplexData(pageData.map(item => item.node), process.env.COUNTRY, "relatedProducts");
    relatedProductsData = relatedProductsData["0"];

    let options = [];
    let skus = [];
    let optionsSizes = [];
    let optionsProductSku = [];
    let optionsPriceSpiderSku = [];

    if (get(transData, 'availableSizes', false)) {
        optionsSizes = transData.availableSizes;
    }

    if (get(transData, 'skuProduct', false)) {
        optionsProductSku = transData.skuProduct;
    }

    if (get(transData, 'skuPriceSpider', false)) {
        optionsPriceSpiderSku = transData.skuPriceSpider;
    }

    let optionsHelper =  optionsSizes.reduce(function(optionsHelper, field, index) {
        optionsHelper[optionsProductSku[index]] = field;
        return optionsHelper;
    }, {})

    let spiderHelper = optionsPriceSpiderSku.reduce(function(optionsHelper, field, index) {
        optionsHelper[optionsProductSku[index]] = field;
        return optionsHelper;
    }, {})

    let resultForSpider = Object.entries(spiderHelper);
    let resultForSelect = Object.entries(optionsHelper);

    resultForSelect.map(el => {
        return(
            options.push({ label: el[1], value: el[0] })
        )
    })

    for(let y = 0; y < options.length; y++) {
        skus.push({ label: get(resultForSpider[y], [1], resultForSpider[0][1]), value: options[y].value})
    }

    console.log('options',options);
    console.log('skus',skus);


    return (
        <>
            <Layout
                title={get(transData, 'productMetaTitle', '')}
                description={get(transData, 'productMetaDescription', '')}
                image={get(transData, 'images[0].file.url', '')}
                pathName={location.pathname}
                product={true}
            >
                <div key={get(product, 'sku', get(product, 'title', ''))} id="maincontent">

                    {product.enableTopSection &&
                        <div className="product-container product-container--top">
                            <div className="product-page container-wide">
                                <div className="product-full">
                                    <div className="product-full__top ">
                                        <div className="row">
                                            <div className="w-100 breadcrumb__container">
                                                <SitemapCrumbs
                                                    className="breadcrumb"
                                                    crumbs={crumbs}
                                                    crumbSeparator=">"
                                                    crumbActiveStyle={{ color: '#E2231A' }}
                                                    crumbLabel={customCrumbLabel} />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="w-35">
                                                <div className="product-full__top--left">
                                                    {get(product, 'images', false) &&
                                                        <Carousel swipeable={product.images.length > 1 ? true : false} showIndicators={false} showStatus={false} showArrows={false} thumbWidth={48} infiniteLoop={true}>
                                                            {product.images.map(({ file, title }, key) => {
                                                                return (
                                                                    <img className="img-fluid" key={key} src={file.url} alt={title} />
                                                                )
                                                            })}
                                                        </Carousel>
                                                    }
                                                </div>
                                            </div>
                                            <div className="w-43">
                                                <div className="product-full__top--center">
                                                    {get(product, 'title', false) &&
                                                        <h1 className="page-title product__title">{product.title}</h1>
                                                    }
                                                    {get(product, 'secondTitle', false) &&
                                                        <h2 className="page-title product__title-second">{product.secondTitle}</h2>
                                                    }
                                                    {get(product, 'subtitle', false) &&
                                                        <h3 className="product__subtitle">{product.subtitle}</h3>
                                                    }

                                                    {/* <div className="yotpo bottomLine"
                                                        data-appkey="VOeNm6QW9NR7zIZbJqf6cHqVuQRIgbceeFCIY8aI"
                                                        data-product-id={get(product, 'yotPoSku', '')}
                                                        data-name={product.title}>
                                                    </div> */}

                                                    {get(product, 'descriptionTitle', false) &&
                                                        <div className="product__description-title">
                                                            <strong>
                                                                {product.descriptionTitle}
                                                            </strong>
                                                        </div>
                                                    }
                                                    {get(product, 'description.description', false) &&
                                                        <div className="product__description-text">
                                                            <Paragraphs value={product.description} />
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                            <div className="w-22">
                                                <div className="product-full__top--right">
                                                    <div className="product__variations">
                                                        <div className="product__variations-label">
                                                            <label htmlFor="product-sizes-select">
                                                                Available Sizes:
                                                            </label>
                                                        </div>
                                                        <div className="product__variations-select">
                                                            <select name="product-sizes-select" id="product-sizes-select" onChange={() => {
                                                                skus.map(item => {
                                                                    let selectedValue = document.getElementById('product-sizes-select').value;
                                                                    if(item.value === selectedValue) {
                                                                        document.querySelector('.ps-widget').setAttribute("ps-sku", item.label);
                                                                        console.log('changed');
                                                                        return(null)
                                                                    }
                                                                    PriceSpider.rebind();
                                                                })
                                                            }} defaultValue="">
                                                                <option value="" disabled>Sizes</option>
                                                                {options.map((option, key) => (
                                                                    <option key={key} value={option.value}>{option.label}</option>
                                                                ))}
                                                            </select>
                                                            <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false" className="css-19bqh2r"><path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path></svg>
                                                        </div>
                                                    </div>
                                                    {process.env.COUNTRY == "en-US" &&
                                                        <div className="ps-widget" ps-sku={get(product, 'priceSpiderSku', product.sku)}></div>
                                                    }
                                                    <p className="product-full__contact"><a href="/contact">Contact Us</a> for more information</p>
                                                    {/* <div className="product__share">
                                                            <div className="product__share-title">Share This</div>
                                                            <div className="product__share-links">
                                                            <div className="social-links">
                                                                <ul>
                                                                    <li>
                                                                        <a href={"https://www.facebook.com/sharer/sharer.php?u=" + location.href} target="_blank" rel="noopener noreferrer">
                                                                            <img src={fbIcon} alt="Facebook Icon" />
                                                                        </a>
                                                                    </li>
                                                                    <li>
                                                                        <a href={"https://twitter.com/intent/tweet?url=" + location.href + "&text=" + product.title} target="_blank" rel="noopener noreferrer">
                                                                            <img src={twitterIcon} alt="Twitter Icon" />
                                                                        </a>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                            </div>
                                                        </div> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }

                    {product.enableTabSection && 
                        <>
                            {(benefitsData !== null || howToUseData !== null || drugFactsIngredientsData !== null) &&
                                <div className={"product-container product-container--tabs product-container product-container--tabs-" + product.category}>
                                    <div className="product-page container-wide">
                                        <div className="product-full__tabs product-full__tabs--desktop">
                                            <Tabs defaultFocus={true}>
                                                <TabList>
                                                    {benefitsData !== null && <Tab>{get(benefitsData, 'tabTex', 'Benefits')}</Tab>}
                                                    {howToUseData !== null && <Tab>{get(howToUseData, 'tabTex', 'How To Use')}</Tab>}
                                                    {drugFactsIngredientsData !== null && <Tab>{get(drugFactsIngredientsData, 'tabTex', 'Drug Facts / Ingredients')}</Tab>}
                                                </TabList>

                                                <TabPanel tabIndex="0">
                                                    {benefitsData !== null && <Benefits entity={benefitsData} />}
                                                </TabPanel>
                                                <TabPanel tabIndex="0">
                                                    {howToUseData !== null && <HowToUse entity={howToUseData} />}
                                                </TabPanel>
                                                <TabPanel tabIndex="0">
                                                    {drugFactsIngredientsData !== null && <DrugFactsIngredients entity={drugFactsIngredientsData} />}
                                                </TabPanel>
                                            </Tabs>
                                        </div>
                                        <div className="product-full__tabs product-full__tabs--mobile">
                                            {benefitsData !== null && <div className="product-full__block">
                                                {benefitsData !== null && <Benefits mobile={true} entity={benefitsData} />}
                                            </div>}
                                            {howToUseData !== null && <div className="product-full__block">
                                                {howToUseData !== null && <HowToUse mobile={true} entity={howToUseData} />}
                                            </div>}
                                            {drugFactsIngredientsData !== null && <div className="product-full__block">
                                                {drugFactsIngredientsData !== null && <DrugFactsIngredients mobile={true} entity={drugFactsIngredientsData} />}
                                            </div>}
                                        </div>
                                    </div>
                                </div>
                            }
                        </>
                    }

                    {product.enableWhyItWorksSection &&
                        <>
                        {whyItWorksData !== null &&
                            <div className="product-container product-container--why-it-works" tabIndex="0">
                                <div className="product-page container-wide">
                                    <div className="product-full__why-it-works">
                                        <WhyItWorks entity={whyItWorksData} />
                                    </div>
                                </div>
                            </div>
                        }
                        </>
                    }

                    {/* <div className="product-container product-container--reviews">
                        <div className="product-page container-m">
                            <div className="product-full__reviews">
                                <div className="yotpo yotpo-main-widget"
                                    data-product-id={get(product, 'yotPoSku', '')}
                                    data-name={get(product, 'title', '') + get(product, 'subtitle', '')}
                                    data-image-url={get(product, 'images["0"].file.url', '')}
                                ></div>
                            </div>
                        </div>
                    </div> */}
                    {product.enableRelatedProductsSection &&
                        <>
                            {relatedProductsData !== null &&
                                <div className="product-container product-container--related-products">
                                    <div className="product-page container-wide">
                                        <div className="product-full__related-products">
                                            <h3 className="related-products__title">Related products</h3>
                                            <div className="related-products__container">
                                                <RelatedProductsSlider {...settings}>
                                                    {relatedProductsData.map((item, key) => {
                                                        return (
                                                            <div className="product-card" key={key}>
                                                                <Link to={"/products/" + item.slug}>
                                                                    {({}.hasOwnProperty.call(item, 'images') && item.images) && <img className="img-fluid" alt="" src={get(item, 'images[0].file.url', '')} />}
                                                                    <p>{item.title}</p>
                                                                    {/* <div className="yotpo bottomLine"
                                                                        data-appkey="VOeNm6QW9NR7zIZbJqf6cHqVuQRIgbceeFCIY8aI"
                                                                        data-product-id={get(item, 'yotPoSku', '')}
                                                                        data-name={item.title}>
                                                                    </div> */}
                                                                </Link>
                                                            </div>
                                                        )
                                                    })}
                                                </RelatedProductsSlider>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                        </>
                    }
                </div>

            </Layout>
        </>
    )
}

ProductPage.propTypes = {
    pageContext: PropTypes.any,
    location: PropTypes.object,
    crumbLabel: PropTypes.any,
    data: PropTypes.any,
}

export default ProductPage

export const query = graphql`
    query ProductPageQuery($id: String!) {
        allContentfulProduct(filter: {sku: {eq: $id}}) {
            edges {
                node {
                    enableTopSection
                    enableTabSection
                    enableWhyItWorksSection
                    enableRelatedProductsSection
                    skuProduct
                    skuPriceSpider
                    productMetaTitle
                    productMetaDescription
                    title
                    secondTitle
                    subtitle
                    descriptionTitle
                    description {
                        description
                    }
                    benefits {
                        title
                        image {
                            file {
                                fileName
                                url
                            }
                            title
                            node_locale
                            contentful_id
                        }
                        benefitsImage {
                            title
                            file {
                                url
                                fileName
                            }
                            title
                            node_locale
                            contentful_id
                        }
                        tabText
                        node_locale
                        contentful_id
                    }
                    category
                    sku
                    priceSpiderSku
                    yotPoSku
                    availableSizes
                    drugFactsIngredients {
                        description {
                            description
                        }
                        title
                        tabText
                        node_locale
                        contentful_id
                        image {
                            file {
                                fileName
                                url
                            }
                            title
                            node_locale
                            contentful_id
                        }
                    }
                    whyItWorks {
                        description {
                            description
                        }
                        image {
                            file {
                                url
                                fileName
                            }
                            title
                            node_locale
                            contentful_id
                        }
                        title
                        node_locale
                        contentful_id
                    }
                    howToUse {
                        title
                        tabText
                        description {
                            description
                        }
                        image {
                            file {
                                url
                                fileName
                            }
                            title
                            node_locale
                            contentful_id
                        }
                        node_locale
                        contentful_id
                    }
                    images {
                        file {
                            url
                            fileName
                        }
                        title
                        node_locale
                        contentful_id
                    }
                    relatedProducts {
                        sku
                        slug
                        title
                        images {
                            file {
                                url
                            }
                            title
                            node_locale
                            contentful_id
                        }
                        node_locale
                        contentful_id
                    }
                    node_locale
                    contentful_id
                }
            }
        }
    }
`