import React from 'react'
import get from 'lodash/get'
import PropTypes from 'prop-types';
import Paragraphs from '../helpers/paragraphs';

const WhyItWorks = (props) => {
    const entity = props.entity;
    return (
        <div className="body body--why-it-works row">
            <div className="left-content w-60">
                <img src={get(entity,'image.file.url', '')} alt={get(entity, 'image.title', 'Why it works image')} />
            </div>
            <div className="right-content w-40">
                <h3>{get(entity, 'title', '')}</h3>
                {get(entity, 'description', false) &&
                    <Paragraphs value={entity.description} />
                }
            </div>
        </div>
    )
}

WhyItWorks.propTypes = {
    entity: PropTypes.object
}

export default WhyItWorks
