import React from 'react'
import get from 'lodash/get'
import PropTypes from 'prop-types';
import Paragraphs from '../helpers/paragraphs';

const Benefits = (props) => {
    const entity = props.entity;
    let benefits = false;

    if (get(entity, 'benefitsImage.length', null) > 0) {
        benefits = entity.benefitsImage.map((row, key) => {
            if(get(row, 'file.url', false)) {
                return (
                    <div key={key} className="body--benefits__icons">
                        <img src={row.file.url} alt=""/>
                        <p><strong>{row.title}</strong></p>
                    </div>
                )
            }
            return(null)
        })
    }


    if (props.mobile) {
        return (
            <div className="container-wide">
                <div className="body body--benefits row">
                    <h2 className="text-center">Benefits</h2>
                    {get(entity, 'image.file.url', false) &&
                        <div className="image">
                            <img className="img-fluid" alt="" src={get(entity, 'image.file.url', '')} />
                        </div>
                    }
                    <div className="text">
                        <h3>{get(entity, 'title', '')}</h3>
                        {get(entity, 'description', false) &&
                            <Paragraphs value={entity.description} />
                        }
                        {benefits !== false && benefits}
                    </div>
                </div>
            </div>
        )
    }
    return (
        <div className="container-wide">
            <div className="body body--benefits row">
                <div className="text w-45">
                    <h3>{get(entity, 'title', '')}</h3>
                    {get(entity, 'description', false) &&
                        <Paragraphs value={entity.description} />
                    }
                    {benefits !== false && benefits}
                </div>
                <div className="image w-55">
                    {get(entity, 'image.file.url', false) &&
                        <img className="img-fluid" alt={get(entity, 'image.title', '')} src={get(entity, 'image.file.url', '')} />
                    }
                </div>
            </div>
        </div>
    )
}

Benefits.propTypes = {
    entity: PropTypes.object,
    mobile: PropTypes.bool
}

export default Benefits
